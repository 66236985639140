<template>
    <div>
        <div class="modal fade custom-fields show" id="payment-info-edit-modal" aria-modal="true" style="display: block; padding-left: 0px;">
            <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
                <form action="">
                    <div class="modal-content" v-on-click-away="closeModal">
                        <div class="modal-header">
                            <h4 class="modal-title text-uppercase">{{ $t("Card Details")}}</h4>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click.prevent="closeModal">
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div class="modal-body">
                            <div class="form-group">
                                <label for="card-element">{{ $t("Credit Or Debit Card")}}*</label>
                                <div ref="card" id="card-element"></div>
                            </div>
                            <div class="form-group">
                                <label for="card_holder_name">{{ $t("Card holder name")}}*</label>
                                <input type="text" v-model="form.card_name" id="card_holder_name" class="form-control" :placeholder="$t('Card Holder Name')">
                                <div class="error-message" v-if="errors.card_name" v-text="errors.card_name.join(' ')"></div>
                            </div>
                        </div>
                        <div class="modal-footer d-flex justify-content-between">
                            <img :src="sponsorImage" alt="sponsor"/>
                            <submit-button :loading="isLoading" :click="savePaymentInfo" type="info-button ml-auto">{{ $t("Save and continue")}}</submit-button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
        <div class="modal-backdrop fade show"></div>
    </div>
</template>

<script>
    import client from "../../../app/api/Client";

    let stripe = Stripe(STRIPE_KEY),
        elements = stripe.elements(),
        card = undefined;

    let style = {
        base: {
            border: '1px solid #D8D8D8',
            borderRadius: '5px',
            color: "#000",
            height: "50px"
        },

        invalid: {
            // All of the error styles go inside of here.
        }

    };

    export default {
        data() {
            return {
                billing: {},
                form: {
                    stripeToken: '',
                    cardType: '',
                    card_name: '',
                },
                sponsorImage: '/img/secure-stripe-payment-logo.svg',
                errors: [],
                isLoading: false
            }
        },
        methods: {
            closeModal() {
                this.$emit('closeModal');
            },

            async savePaymentInfo() {
                try {
                    this.isLoading = true;
                    let self = this;
                    await stripe.createToken(card).then(function (result) {
                        if (result.error) {
                            self.$toast.error(result.error.message);
                            self.isLoading = false;
                        } else {
                            self.form.stripeToken = result.token.id;
                            self.form.cardType = result.token.card.brand;

                            client().post(`/my-account/payment`, self.form).then(({data}) => {
                                if (data.status === 'SUCCESS') {
                                    self.$toast.success(data.message);
                                    self.closeModal();
                                } else {
                                    self.$toast.error(data.message);
                                }
                            }).catch(err => {
                                if (err.response.status === 422) {
                                    self.errors = err.response.data.message;
                                    self.$toast.error(this.$t('Oops! Please check your input'));
                                } else if (err.response.status === 307) {
                                    self.$toast.info(err.response.data.message);
                                    window.location.href = err.response.data.url;
                                } else {
                                    self.$toast.error(err.response.data.message);
                                }
                            }).finally(() => {
                                self.isLoading = false;
                            });
                        }
                    });
                } catch (e) {
                }
            }
        },
        async mounted() {
            if (!elements.getElement('card')) {
                card = elements.create('card', style);
            }
            card.mount(this.$refs.card);
        }
    }
</script>
<style>
    #card-element {
        background-color: #f5f7fd;
        border: 1px solid rgba(89, 125, 252, 0.3);
        height: 50px;
        padding: 15px 26px;
        font-size: 0.875rem;
        font-weight: 500;
        border-radius: 5px;
        color: #1c2238;
    }
</style>
